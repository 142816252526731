/**
 * @typedef NextPreviousOutputs
 * @param {Function} focusNext - move focus to the next focusable item in the
 * ref DOM node
 * @param {Function} focusPrevious - move focus to the previous focusable item
 * in the ref DOM node
 */

/**
 *
 * @param {Object} ref - React DOM node ref
 * @returns {NextPreviousOutputs}
 */
const useNextPrevious = (ref) => {
  const getFocusableElements = () => {
    const { current } = ref;
    const focusedElement = current.querySelector(':focus');
    const focusableElements = current.querySelectorAll('a, button');
    const focusIndex = [...focusableElements].indexOf(focusedElement);

    return {
      focusableElements,
      focusIndex,
    };
  };

  const focusNext = () => {
    const { focusableElements, focusIndex } = getFocusableElements();

    if (focusIndex === focusableElements.length - 1) {
      focusableElements[0].focus();
      return;
    }

    focusableElements[focusIndex + 1].focus();
  };

  const focusPrevious = () => {
    const { focusableElements, focusIndex } = getFocusableElements();

    if (focusIndex <= 0) {
      focusableElements[focusableElements.length - 1].focus();
      return;
    }

    focusableElements[focusIndex - 1].focus();
  };

  return { focusNext, focusPrevious };
};

export default useNextPrevious;
