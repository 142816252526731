import React from 'react';

import { Curve, Grid, Section, Surface } from 'site-react/components/page';
import { Heading, Paragraph } from 'site-react/components/typography';
import { VerticalSpacing } from 'site-react/components/utility';
import config from 'site-react/config';
import getAppUrl from 'site-react/helpers/getAppUrl';
import useLocalStorage from 'site-react/hooks/useLocalStorage';

import ProductCard from '../ProductCard';

const PopularSpaces = () => {
  const [isFullTimeSearchFormCompleted] = useLocalStorage(
    'fullTimeSearchFormCompleted',
  );

  const fullTimeEnquiryFormUrl = new URL(
    '/full-time-office-enquiry',
    getAppUrl(config),
  );
  fullTimeEnquiryFormUrl.searchParams.set('source', 'homepage');

  const passLink = '/pass/go';
  const urlPassLondonMeetingRoom = `${passLink}?productType=meeting-room`;
  const urlPassLondonPrivateOffice = `${passLink}?productType=private-office`;
  const urlPassLondonCoworking = `${passLink}?productType=coworking`;
  const urlPassEventSpace = 'https://search.hubblehq.com/events/';
  const urlPermanentOffices = isFullTimeSearchFormCompleted
    ? '/office-space-london'
    : fullTimeEnquiryFormUrl;

  const imageUrlPrefix = '/site/homepage/popular-spaces';
  const images = {
    coworkingDesk: `${imageUrlPrefix}/coworking.jpg`,
    eventSpace: `${imageUrlPrefix}/event-space.jpeg`,
    hybridSolutions: `${imageUrlPrefix}/hybrid-solutions.jpg`,
    meetingRoom: `${imageUrlPrefix}/meeting-room.jpg`,
    permanentOffice: `${imageUrlPrefix}/permanent-office.jpg`,
    privateDayOffice: `${imageUrlPrefix}/private-day-office.jpg`,
  };

  return (
    <Surface backgroundColor="gradient-pinkblue">
      <Curve
        bottomColor="transparent"
        height="lg"
        topColor="white"
        type="wave-inverted"
      />
      <Section verticalPadding="xxxl">
        <VerticalSpacing size="lg" />
        <Heading isCentered level="2" type="title1">
          Any type of workspace for every type of work
        </Heading>
        <VerticalSpacing size="lg" />
        <Paragraph isCentered type="content1">
          From on&#8209;demand space to longer&#8209;term offices – Hubble is
          the leading workspace marketplace
        </Paragraph>
        <VerticalSpacing size="xl" />
        <Grid>
          <Grid.Item colSpan="4">
            <ProductCard
              badgeLabel="1–24+ MONTH TERMS"
              description="Entire offices to rent"
              href={urlPermanentOffices}
              imageAlt="Permanent Offices"
              imageSrc={images.permanentOffice}
              productName="Permanent Offices"
            />
          </Grid.Item>
          <Grid.Item colSpan="4">
            <ProductCard
              badgeLabel="Book daily"
              description="Your team’s very own office, just for the day"
              href={urlPassLondonPrivateOffice}
              imageAlt="Private Day Offices"
              imageSrc={images.privateDayOffice}
              productName="Private Day Offices"
            />
          </Grid.Item>
          <Grid.Item colSpan="4">
            <ProductCard
              badgeLabel="Book hourly or daily"
              description="Rooms to meet, plan, and present"
              href={urlPassLondonMeetingRoom}
              imageAlt="Meeting Rooms"
              imageSrc={images.meetingRoom}
              productName="Meeting Rooms"
            />
          </Grid.Item>
          <Grid.Item colSpan="4">
            <ProductCard
              badgeLabel="Book daily"
              description="Desks in a shared workplace"
              href={urlPassLondonCoworking}
              imageAlt="Coworking Desks"
              imageSrc={images.coworkingDesk}
              productName="Coworking Desks"
            />
          </Grid.Item>
          <Grid.Item colSpan="4">
            <ProductCard
              badgeLabel="Book daily"
              description="Spaces to host events and company get-togethers"
              href={urlPassEventSpace}
              imageAlt="Event Space"
              imageSrc={images.eventSpace}
              linkType="OutboundLink"
              productName="Event Space"
              target="_blank"
            />
          </Grid.Item>
          <Grid.Item colSpan="4">
            <ProductCard
              badgeLabel="Hybrid solutions"
              description="Combine permanent and on&#8209;demand workspace"
              href={{
                pathname: '/onboarding',
                query: {
                  type: 'workspace-options',
                  utm_campaign: 'workspace',
                  utm_medium: 'website',
                  utm_source: 'homepagehybridsolution',
                },
              }}
              imageAlt="Permanent and on-demand workspace"
              imageSrc={images.hybridSolutions}
              productName="Need a mix?"
            />
          </Grid.Item>
        </Grid>
      </Section>
      <Curve
        bottomColor="white"
        height="lg"
        topColor="transparent"
        type="wave"
      />
    </Surface>
  );
};

export default PopularSpaces;
