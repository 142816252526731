import PropTypes from 'prop-types';
import React, { createRef, useEffect, useMemo } from 'react';

import { GlobalEvents, keyEventsHandler } from 'site-react/helpers/Events';
import getAnchor from 'site-react/helpers/getAnchor';
import useNextPrevious from 'site-react/hooks/useNextPrevious';

import styles from './Suggestions.module.css';

const formatResult = (matchHtml, areas) => {
  const length = areas?.length;

  return length
    ? `${matchHtml}<small>${
        areas[length - 1]?.name ? `, ${areas[length - 1].name}` : ``
      }${areas[length - 2]?.name ? `, ${areas[length - 2].name}` : ``}</small>`
    : matchHtml;
};

/**
 * Use `Suggestions` to display location typeahead results.
 */
const Suggestions = ({ results = [], updateLocation }) => {
  const suggestions = createRef();
  const { focusNext, focusPrevious } = useNextPrevious(suggestions);
  const areas = results.filter((result) => result.slug);
  const globalEvents = useMemo(
    () =>
      new GlobalEvents({
        keydown: {
          handler: keyEventsHandler({
            ArrowDown: (event) => {
              event.preventDefault();
              focusNext();
            },
            ArrowUp: (event) => {
              event.preventDefault();
              focusPrevious();
            },
          }),
        },
      }),
    [focusNext, focusPrevious],
  );

  useEffect(() => {
    globalEvents.listen();

    return () => globalEvents.remove();
  }, [globalEvents]);

  return (
    <div ref={suggestions}>
      {Boolean(areas.length) && (
        <ul aria-label="Suggested Areas" className={styles['Suggestions-list']}>
          {areas.map((area) => {
            const anchor = getAnchor(area);
            const { ancestors, matchHtml, name, slug } = area;
            const resultHtml = formatResult(matchHtml, ancestors);

            return (
              <li aria-label={name} key={slug}>
                <button
                  aria-label={name}
                  className={styles['Suggestions-button']}
                  // matchHTML includes <b></b> around the search query.
                  // Considered safe as coming from internal API.
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{ __html: resultHtml }}
                  onClick={() => {
                    if (anchor === area) {
                      updateLocation(name, anchor.slug);
                    } else {
                      updateLocation(name, anchor.slug, area.slug);
                    }
                  }}
                  type="button"
                />
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

Suggestions.propTypes = {
  /**
   * The Suggestions to display.
   */
  results: PropTypes.arrayOf(
    PropTypes.shape({
      matchHtml: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      pathname: PropTypes.string,
      slug: PropTypes.string,
      timeZone: PropTypes.string,
    }),
  ),

  /**
   * Callback function to set a new area. Called with name & slug strings
   * as the two required arguments.
   */
  updateLocation: PropTypes.func.isRequired,
};

export default Suggestions;
