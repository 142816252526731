/**
 * Given an area object or a list of areas, returns the nearest anchor.
 *
 * If you have an area object (with an ancestors property), you can pass it
 * directly, like in `getAnchor(area)`
 *
 * If you have a building object (with an areas property), you can pass the
 * areas property, like in `getAnchor(building.areas)`
 *
 * @param {*} areaOrAreas the area, or a list of areas
 */
export default function getAnchor(areaOrAreas) {
  let ancestors;
  if (Array.isArray(areaOrAreas)) {
    ancestors = areaOrAreas;
  } else {
    if (areaOrAreas.isAnchor) {
      // Area _is_ an Anchor
      return areaOrAreas;
    }

    ancestors = areaOrAreas.ancestors;
  }

  // Search back to front - we want the closest anchor, but ancestors is ordered
  // closest last.
  const reverseAncestors = [...(ancestors ?? [])].reverse();
  const anchor = reverseAncestors.find((ancestor) => ancestor.isAnchor);

  return anchor ?? null;
}
