import PropTypes from 'prop-types';
import React from 'react';

import { UICard } from 'site-react/components/page';
import {
  Heading,
  MaterialIcon,
  Paragraph,
} from 'site-react/components/typography';
import { VerticalSpacing } from 'site-react/components/utility';

const UspCards = ({ uspSet = 'hq' }) => {
  const uspContent = {
    coworking: [
      {
        description: 'Providers',
        title: 'Top workspace',
      },
      {
        description: 'Available',
        title: 'Team bookings',
      },
      {
        description: 'Real listings',
        title: '100%',
      },
    ],
    hq: [
      {
        description: 'Market coverage',
        title: '100%',
      },
      {
        description: 'Real listings',
        title: '100%',
      },
      {
        description: 'Guarantee',
        title: 'Best price',
      },
    ],
    'meeting-rooms': [
      {
        description: 'With top facilities',
        title: 'Fully equipped',
      },
      {
        description: 'Hourly or daily',
        title: 'Book',
      },
      {
        description: 'Real listings',
        title: '100%',
      },
    ],
    'private-day-offices': [
      {
        description: 'For what you use',
        title: 'Only pay',
      },
      {
        description: 'By the day',
        title: 'Book',
      },
      {
        description: 'Real listings',
        title: '100%',
      },
    ],
  };

  return (
    <div
      style={{ display: 'flex', gap: `var(--space-md)`, overflow: 'scroll' }}
    >
      {uspContent[uspSet].map((content) => (
        <UICard
          key={`${content.title} ${content.description}`}
          style={{
            borderRadius: `var(--space-sm)`,
            minWidth: `calc(var(--space-base) * 50)`,
            padding: `var(--space-md)`,
          }}
        >
          <div
            style={{
              color: `var(--color-neutral-900)`,
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Heading type="title3">{content.title}</Heading>
              <VerticalSpacing size="xs" />
              <Paragraph>{content.description}</Paragraph>
            </div>
            <MaterialIcon iconType="star" />
          </div>
        </UICard>
      ))}
    </div>
  );
};

UspCards.propTypes = {
  /**
   * The USP set to be displayed.
   */
  uspSet: PropTypes.oneOf([
    'hq',
    'meeting-rooms',
    'private-day-offices',
    'coworking',
  ]),
};

export default UspCards;
