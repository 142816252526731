import PropTypes from 'prop-types';
import React from 'react';

import { Heading } from 'site-react/components/typography';
import { VerticalSpacing } from 'site-react/components/utility';
import CompanyLogos, {
  logos as logoList,
} from 'site-react/features/CompanyLogos';

const PressBar = ({ logos }) => (
  <>
    <VerticalSpacing size="xxxl">
      <Heading isCentered level="3" type="title3">
        As featured in
      </Heading>
    </VerticalSpacing>
    <CompanyLogos logos={logos} />
  </>
);

PressBar.propTypes = {
  /**
   * An array of logo types to display in the press bar.
   */
  logos: PropTypes.arrayOf(PropTypes.oneOf(Object.keys(logoList))).isRequired,
};

export default PressBar;
