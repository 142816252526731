import React from 'react';

import { LinkAsButton } from 'site-react/components/navigation';
import {
  ContentWithBlob,
  Curve,
  Grid,
  Section,
  Surface,
} from 'site-react/components/page';
import { Heading, Paragraph } from 'site-react/components/typography';
import { Centered, VerticalSpacing } from 'site-react/components/utility';
import AdvisorPhotos from 'site-react/features/AdvisorPhotos';
import CompanyLogos from 'site-react/features/CompanyLogos';

import Blurb from './components/Blurb';
import OnDemandForTeam from './components/OnDemandForTeam';
import PermanentOfficeSearch from './components/PermanentOfficeSearch';
import PopularSpaces from './components/PopularSpaces';
import PressBar from './components/PressBar';
import SearchHero from './components/SearchHero';
import HelpWithSearch from './components/SearchHero/HelpWithSearch';
import content from './content';
import styles from './Home.module.css';
import { Testimonials } from '../Testimonials';

function SearchHeroWrapper({ children }) {
  return <div className={styles['Home-searchHeroWrapper']}>{children}</div>;
}

const Home = () => (
  <>
    <Surface
      backgroundColor={{
        default: 'neutral-900',
        lg: 'gradient-pinkblue',
      }}
    >
      <Section verticalPadding="xl">
        <SearchHeroWrapper>
          <SearchHero />
        </SearchHeroWrapper>
        <div className={styles['Home-helpWithSearchCta']}>
          <HelpWithSearch />
        </div>
      </Section>

      <div className={styles['Home-curve']}>
        <Curve
          bottomColor="white"
          height="lg"
          topColor="transparent"
          type="wave"
        />
      </div>
    </Surface>
    <Section verticalPadding="xxl">
      <VerticalSpacing size="xl" />
      <Heading isCentered type="title1">
        Trusted by the world’s top brands
      </Heading>
      <VerticalSpacing size="xl" />
      <CompanyLogos
        isGrid
        logos={[
          'taskRabbit',
          'monzoColor',
          'citymapperColor',
          'starlingColor',
          'accenture',
          'trustpilotColor',
          'nhsColor',
          'oddbox',
        ]}
      />
    </Section>
    <PopularSpaces />
    <VerticalSpacing size="xxl" />
    <OnDemandForTeam />
    <PermanentOfficeSearch />
    <div className={styles['Home-testimonials']}>
      <Testimonials />
    </div>
    <Curve
      bottomColor="neutral-900"
      height="lg"
      topColor="brandsecondary"
      type="down"
    />
    <Surface backgroundColor="neutral-900">
      <Section verticalPadding="xxl">
        <VerticalSpacing size="sm" />
        <Heading color="white" isCentered level="2" type="title1">
          Use Cases
        </Heading>
        <VerticalSpacing size="xxxl" />
        <Grid>
          {content.USP.map((blurb) => (
            <Grid.Item colSpan="4" justify="center" key={blurb.title}>
              <Blurb {...blurb} />
            </Grid.Item>
          ))}
        </Grid>
        <VerticalSpacing size="xxl" />
        <Centered>
          <LinkAsButton
            analyticsMetadata={{
              data_analytics_id: 'home-page-get-started-for-free-cta',
            }}
            href={{
              pathname: '/onboarding',
              query: {
                type: 'workspace-options',
                utm_campaign: 'workspace',
                utm_medium: 'website',
                utm_source: 'homepageusecases',
              },
            }}
            name="Get recommendations"
            styleType="primary"
          >
            Get started for free
          </LinkAsButton>
        </Centered>
        <VerticalSpacing size="xxxl" />
      </Section>
    </Surface>
    <Surface backgroundColor="gradient-pinkblue">
      <Curve
        bottomColor="transparent"
        height="lg"
        topColor="neutral-900"
        type="wave-inverted"
      />
      <Section verticalPadding="xxxl">
        <AdvisorPhotos
          advisors={['BlueChai', 'BlueBeca', 'BlueHenryb']}
          photoSize="medium"
        />
        <VerticalSpacing size="lg" />
        <Heading isCentered level="2" type="title1">
          Meet our workspace experts
        </Heading>
        <VerticalSpacing size="lg" />
        <Grid>
          <Grid.Item colSpan="6" colStart="4">
            <Paragraph isCentered type="content1">
              Our team can help you ensure that the future of where you work is
              exactly where it should be. Whether you’re speccing out a global
              HQ or spinning out a local hub, we’re here to help.
            </Paragraph>
          </Grid.Item>
        </Grid>
        <VerticalSpacing size="xxl" />
        <Grid>
          <Grid.Item colSpan="4" colStart="5" justify="center">
            <LinkAsButton
              analyticsMetadata={{
                data_analytics_id: 'home-page-book-a-call-cta',
              }}
              href="https://hubblehq.com/meet-with-hubble"
              name="Book a call"
              styleType="primary"
              target="_blank"
            >
              Book a call
            </LinkAsButton>
          </Grid.Item>
        </Grid>
        <VerticalSpacing size="xl" />
        <Grid>
          <Grid.Item colSpan="12" justify="center">
            <Paragraph isCentered>
              Call us:{' '}
              <a
                href="tel:+442036080215"
                rel="noopener noreferrer"
                target="_blank"
              >
                <b>+44 20 3608 0215</b>
              </a>
            </Paragraph>
          </Grid.Item>
          <Grid.Item colSpan="12" justify="center">
            <Paragraph isCentered>
              Email:{' '}
              <a
                href="mailto:advisors@hubblehq.com?subject=#Ref 800 - New Inquiry"
                rel="noopener noreferrer"
                target="_blank"
              >
                <b>advisors@hubblehq.com</b>
              </a>
            </Paragraph>
          </Grid.Item>
        </Grid>
      </Section>

      <Curve
        bottomColor="white"
        height="lg"
        topColor="transparent"
        type="wave"
      />
    </Surface>
    <Section verticalPadding="xxxl">
      <Heading isCentered level="2" type="title1">
        About Hubble
      </Heading>
      <VerticalSpacing size="lg" />
      <Grid>
        <Grid.Item colSpan="8" colStart="3">
          <Heading isCentered level="3" type="title3">
            Hubble makes hybrid working easy.
          </Heading>
          <VerticalSpacing size="md" />
          <Paragraph isCentered type="content1">
            We’re the only place that helps you configure, deliver and manage
            all your space in one place. Whether that’s the HQ, home or
            on-demand.
          </Paragraph>
          <Paragraph isCentered type="content1">
            Our mission is to improve the working lives of one million people by
            2025.
          </Paragraph>
          <Paragraph isCentered type="content1">
            We know your people are your most valuable asset. Let us help you
            create a workplace where they can truly thrive.
          </Paragraph>
        </Grid.Item>
      </Grid>
      <VerticalSpacing size="xxl" />
      <Grid>
        {content.about.map((aboutContent) => (
          <Grid.Item colSpan="4" justify="center" key={aboutContent.title}>
            <ContentWithBlob {...aboutContent} />
          </Grid.Item>
        ))}
      </Grid>
      <VerticalSpacing size="xxl" />

      <Grid>
        <Grid.Item colSpan="12" justify="center">
          <Heading isCentered level="3" type="title3">
            Help your team thrive with Hubble
          </Heading>
          <VerticalSpacing size="lg" />
          <Centered>
            <LinkAsButton
              analyticsMetadata={{
                data_analytics_id: 'home-page-get-recommendations-cta',
              }}
              href={{
                pathname: '/onboarding',
                query: {
                  type: 'workspace-options',
                  utm_campaign: 'workspace',
                  utm_medium: 'website',
                  utm_source: 'homepagerecs',
                },
              }}
              name="Get recommendations"
              styleType="primary"
            >
              Get recommendations
            </LinkAsButton>
          </Centered>
        </Grid.Item>
      </Grid>
    </Section>
    <VerticalSpacing size="xxl" />
    <PressBar
      logos={[
        'wired',
        'financialTimes',
        'techCrunch',
        'guardian',
        'forbes',
        'telegraph',
      ]}
    />
    <VerticalSpacing size="xxxl" />
    <Curve bottomColor="neutral-900" topColor="white" type="wave" />
  </>
);

export default Home;
