import HubbleBaseApi from 'site-react/api/HubbleBaseApi';

/**
 * @typedef ListingsQuery
 * @property {string} coordinates - a comma separated list of coordinates. when there are more than two in the list, this will be broken down in to pairs.
 * @property {string} facilities - a comma separated list of facilty slugs.
 * @property {string} filterPricePlans - should be true if we want to only show one office in the offices part of the payload. If we queried by pricePlanId, this will be the office with that id.
 * @property {string} hostIds - a comma separated list of host ids. If present, we will filter to only show listings belonging to these hosts.
 * @property {string} locationType - the geojson shape type- currently we only use circle and polygon. check out the geojson docs for more info.
 * @property {string} peopleMax - the max number of people in our search.
 * @property {string} peopleMin - the min number of people in our search.
 * @property {string} priceMax - the max price in our search.
 * @property {string} priceMin - the min price in our search.
 * @property {string} pricePlanIds - a comma separated price plan ids. elastic search will only return the listings with price plans with these price plan ids.
 * @property {string} radius - the radius for the location part of the search. valid examples would be 10km, 1000m, etc.
 * @property {string} seatingConfig - a comma separated list of the seatingConfigs we want to filter by. For now we only support 'private-office'.

 *
 * @typedef AreasQuery
 * @property {string} parentArea - the parent of the area we want to search for, if more clarity is needed (ie. picadilly in manchester or london)
 * @property {string} searchArea - the area we want to search for

  *
  * @typedef AutocompleteQuery
  * @property {string} searchString - the text to search for
  */

class SearchApi extends HubbleBaseApi {
  /**
   * Performs a search using the search service at the listings endpoint, based on the query in the payload
   * @param {ListingsQuery} payload the search parameters
   */
  async getListings(payload) {
    return this.get('search/listings', payload);
  }

  /**
   * Performs a search using the search service at the PricePlans endpoint, based on the query in the payload
   * @param {ListingsQuery} payload the search parameters
   */
  async getPricePlans(payload) {
    return this.get('search/priceplans', payload);
  }

  /**
   * Performs a search using the search service at the areas endpoint, based on the query in the payload
   * @param {AreasQuery} payload the search parameters
   */
  async getAreas(payload) {
    return this.get('search/areas', payload);
  }

  /**
   * Performs a search using the search service at the autocomplete endpoint, based on the query in the payload
   * @param {AutocompleteQuery} payload the search parameters
   */
  async getAutocomplete(payload) {
    return this.get('search/autocomplete', payload);
  }
}

export default SearchApi;
