import PropTypes from 'prop-types';
import React from 'react';

import { Centered, VerticalSpacing } from 'site-react/components/utility';

import styles from './TestimonialCard.module.css';

const TestimonialCard = ({
  authorImage,
  authorLogo,
  authorText,
  authorTitleText,
  children,
}) => (
  <figure className={styles.TestimonialCard}>
    <VerticalSpacing size="md" />
    <div className={styles['TestimonialCard--centered']}>{authorImage}</div>
    <VerticalSpacing size="lg" />

    {children}

    <figcaption>
      <Centered>
        <p className={styles['TestimonialCard-author']}>{authorText}</p>
        <span className={styles['TestimonialCard-authorTitle']}>
          {authorTitleText}
        </span>
      </Centered>
    </figcaption>
    <VerticalSpacing size="md" />
    <div className={styles['TestimonialCard--centered']}>{authorLogo}</div>
    <VerticalSpacing size="md" />
  </figure>
);

TestimonialCard.propTypes = {
  /**
   * Image of the author, next/image element expected.
   */
  authorImage: PropTypes.node.isRequired,

  /**
   * Image of the author's company logo, next/image element expected.
   */
  authorLogo: PropTypes.node,

  /**
   * Authors Name
   */
  authorText: PropTypes.string.isRequired,

  /**
   * Author's title
   */
  authorTitleText: PropTypes.string.isRequired,

  /**
   * Quotation body. Expecting Paragraphs and similar inline elements
   */
  children: PropTypes.node,
};

export default TestimonialCard;
