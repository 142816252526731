import {
  balancedWorkEnvironment,
  fullyRemote,
  hybrid,
  officeFirst,
} from 'site-react/assets';

export default {
  about: [
    {
      blobImg: 'blob1',
      content: 'Faster than the average office search',
      textColor: 'white',
      title: '400%',
    },
    {
      blobImg: 'blob2',
      content: 'Google star rating',
      stagger: 'xxxl',
      textColor: 'neutral900',
      title: '4.9/5',
    },
    {
      blobImg: 'blob3',
      content: 'Market coverage',
      stagger: 'xl',
      textColor: 'neutral900',
      title: '100%',
    },
  ],
  hero: {
    illustration: balancedWorkEnvironment,
    illustrationAlt: 'Figures enjoying a balanced work environment',
    rating: {
      alt: 'Hubble Trustpilot rating: 4.5 Stars',
      height: '24',
      src: '/site/workplace-strategy/trustpilot.png',
      width: '282',
    },
  },
  USP: [
    {
      content: 'Find a new HQ for your whole team. With added flexibility',
      id: 1,
      illustration: officeFirst,
      title: 'Office-first',
    },
    {
      content: 'Find your unique blend of HQ, on-demand access and WFH perks',
      id: 2,
      illustration: hybrid,
      title: 'Hybrid',
    },
    {
      content:
        'Improve your remote team’s experience with on-demand workspace access and WFH perks',
      id: 3,
      illustration: fullyRemote,
      title: 'Remote-first',
    },
  ],
};
