import Image from 'next/image';
import React, { useRef } from 'react';

import { Heading, Paragraph } from 'site-react/components/typography';
import { VerticalSpacing } from 'site-react/components/utility';
import getImgixPathWithParameters from 'site-react/helpers/getImgixPathWithParameters';
import imgixLoader from 'site-react/helpers/imgixLoader';

import TestimonialCard from './components/TestimonialCard';
import styles from './Testimonials.module.css';

const Testimonials = () => {
  const scrollContainerRef = useRef(null);

  const testimonialCards = [
    <TestimonialCard
      authorImage={
        <Image
          alt="Photo of Charlie Thuillier"
          height={128}
          loader={imgixLoader}
          src={getImgixPathWithParameters('/site/about/oppo.png', {})}
          width={137}
        />
      }
      authorLogo={
        <Image
          alt=""
          height={41}
          loader={imgixLoader}
          src={getImgixPathWithParameters('/site/about/oppo-logo.png', {})}
          width={77}
        />
      }
      authorText="Charlie Thuillier"
      authorTitleText="Founder &amp; Managing Director"
      key="olioTestimonialCard"
    >
      <Paragraph isCentered type="content2">
        “<b>Hubble simplifies what could be a really stressful move.</b> They
        helped a fast-paced company like us to keep running smoothly instead of
        investing lots of time in viewings and false starts.
      </Paragraph>
      <Paragraph isCentered type="content2">
        Hubble understands how important it is to find personality in a space
        and make it your own. We are thriving in our new office.”
      </Paragraph>
    </TestimonialCard>,
    <TestimonialCard
      authorImage={
        <Image
          alt="Photo of Rachel Carrell"
          height={128}
          loader={imgixLoader}
          src={getImgixPathWithParameters(
            '/site/homepage/client-rachel-carrell.png',
            {},
          )}
          width={163}
        />
      }
      authorLogo={
        <Image
          alt="Korukids logo"
          height={26}
          loader={imgixLoader}
          src={getImgixPathWithParameters(
            '/site/homepage/logo-korukids.png',
            {},
          )}
          width={106}
        />
      }
      authorText="Rachel Carrell"
      authorTitleText="Founder & CEO"
      key="koruKidsTestimonialCard"
    >
      <Paragraph isCentered type="content2">
        “
        <b>
          Hubble has been a sanity-saver for our team, and a happy financial
          surprise for our accountants!
        </b>
      </Paragraph>
      <Paragraph isCentered type="content2">
        We went from having a huge, expensive office to a much more flexible
        working arrangement, with a small central office and on-demand access to
        lots of great workspaces across London.”
      </Paragraph>
    </TestimonialCard>,
    <TestimonialCard
      authorImage={
        <Image
          alt="Photo of Alex Blakoe"
          height={128}
          loader={imgixLoader}
          src={getImgixPathWithParameters(
            '/site/homepage/client-alex-blakue.png',
            {},
          )}
          width={163}
        />
      }
      authorLogo={
        <Image
          alt="Cievert logo"
          height={30}
          loader={imgixLoader}
          src={getImgixPathWithParameters(
            '/site/homepage/logo-cievert.png',
            {},
          )}
          width={121}
        />
      }
      authorText="Alex Blakoe"
      authorTitleText="Chief Operating Officer"
      key="cievertTestimonialCard"
    >
      <Paragraph isCentered type="content2">
        “As a company with customers and employees spread across the UK, it
        makes complete sense for us to use Hubble Pass. It’s an{' '}
        <b>
          extremely cost-effective solution, which gives complete flexibility,
          with no long-term commitments to just one location.
        </b>
      </Paragraph>
      <Paragraph isCentered type="content2">
        You just pick a convenient location and turn up on the day - it’s as
        simple as that.”
      </Paragraph>
    </TestimonialCard>,
  ];
  return (
    <>
      <Heading isCentered level="2" type="title1">
        Happy Hubble clients
      </Heading>
      <VerticalSpacing size="xxl" />

      <div className={styles['Testimonial-carousel']}>
        <div
          className={styles['Testimonial-card']}
          id="testimonial-carousel"
          ref={scrollContainerRef}
        >
          {testimonialCards.map((testimonialCard) => (
            <figure
              className={styles['Testimonial-figure']}
              key={testimonialCard.key}
            >
              {testimonialCard}
            </figure>
          ))}
        </div>
      </div>
    </>
  );
};

export default Testimonials;
